import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '@/redux/store';

type UseAddStripeCardType = {
  onSuccess: () => void;
};

const useAddStripeCard = ({ onSuccess }: UseAddStripeCardType) => {
  const dispatch = useDispatch<Dispatch>();
  const stripe = useStripe();

  const elements = useElements();
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardDetailsValid, setCardDetailsValid] = useState({
    cardNumber: false,
    expiryDate: false,
    cvv: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errorMessageStripe, setErrorMessageStripe] = useState<string | null>(null);

  const email = useSelector((state: RootState) => state?.auth?.user?.account?.email);

  const handleCardDetail = ({ event, name }: any) => {
    setCardDetailsValid({ ...cardDetailsValid, [name]: event.complete || event });
  };

  const createPurchaseStripe = async () => {
    try {
      setIsLoading(true);

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        return;
      }

      const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          name: cardHolderName,
          email: email,
        },
        card: cardElement,
      });

      if (paymentError) {
        setErrorMessageStripe(paymentError.message || null);
        return;
      }

      const res = await dispatch.auth.createPaymentMethodStripe({
        paymentMethodId: paymentMethod.id,
        email,
      });
      if (res) {
        await dispatch.auth.getSubscriptionStatus();
        onSuccess();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardHolderNameChange = (value: string) => {
    setCardHolderName(value);
  };

  const isStripeCardDetailsValid = !!(
    cardDetailsValid.cardNumber &&
    cardDetailsValid.cvv &&
    cardDetailsValid.expiryDate &&
    cardHolderName?.length
  );

  return {
    handleCardDetail,
    createPurchaseStripe,
    errorMessageStripe,
    cardHolderName,
    handleCardHolderNameChange,
    isLoading,
    isStripeCardDetailsValid,
  };
};
export default useAddStripeCard;
