import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as PayPalLogoColor } from '@/assets/paypalColorLogo.svg';
import { ReactComponent as StripeLogo } from '@/assets/stripeLogo.svg';
import Button from '@/components/Button/Button';
import { InputTextField, LoaderComponent } from '@/components/ui';

import useStripeAndPayPal, { StripePayPalProps } from './useStripeAndPaypal';

const StripeAndPaypal = ({
  selectedPlan,
  classNames = '',
  paymentSuccessHandler,
  discount,
}: StripePayPalProps) => {
  const {
    handleCardDetail,
    handlePayPalClick,
    stripeErrorMessage,
    onSubmit,
    isLoading,
    isDisableSubmitButton,
    handleSubmit,
    isGeneratingPaypalToken,
  } = useStripeAndPayPal({ selectedPlan, paymentSuccessHandler, discount });

  return (
    <div className={twMerge('flex  w-full max-w-full flex-col pt-6', classNames)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full flex-col gap-3">
          <div className="flex flex-col justify-between rounded-lg border p-6">
            <div className="mb-2 flex flex-col">
              <StripeLogo className="mb-5 h-fit w-12" aria-label="Stripe Logo" />
              <div className="space-y-4">
                <div className="flex gap-4">
                  <div className="flex w-full max-w-[344px] flex-col space-y-2">
                    <p className="text-gray-700">Name on card</p>
                    <InputTextField
                      width="w-full"
                      onChange={e => handleCardDetail({ name: 'cardName', event: e })}
                      onInput={e => handleCardDetail({ name: 'cardName', event: e })}
                    />
                  </div>
                  <div className="flex w-full max-w-[112px] flex-col space-y-2">
                    <p className="text-gray-700">Expiry</p>
                    <CardExpiryElement
                      onChange={e => handleCardDetail({ name: 'expiryDate', event: e })}
                      onReady={e => handleCardDetail({ name: 'expiryDate', event: e })}
                      options={{
                        classes: {
                          base: 'h-[44px] w-full bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px] focus:ring-2 focus:ring-green-500 focus:border-green-500',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex w-full max-w-[344px] flex-col space-y-2">
                    <p className="text-gray-700">Card number</p>
                    <CardNumberElement
                      onChange={e => handleCardDetail({ name: 'cardNumber', event: e })}
                      onReady={e => handleCardDetail({ name: 'cardNumber', event: e })}
                      options={{
                        classes: {
                          empty: 'border-gray-300',
                          base: 'h-[44px] text-xl bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px] focus:ring-2 focus:ring-green-500 focus:border-green-500',
                          invalid: 'border-red-500',
                        },
                      }}
                    />
                  </div>
                  <div className="flex w-full max-w-[112px] flex-col space-y-2">
                    <p className="text-gray-700">CVV</p>
                    <CardCvcElement
                      onChange={e => handleCardDetail({ name: 'cvv', event: e })}
                      onReady={e => handleCardDetail({ name: 'cvv', event: e })}
                      options={{
                        classes: {
                          base: 'h-[44px] w-full bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px] focus:ring-2 focus:ring-green-500 focus:border-green-500',
                        },
                      }}
                    />
                  </div>
                </div>

                {stripeErrorMessage && (
                  <span className="text-sm text-red-500">{stripeErrorMessage}</span>
                )}
              </div>
            </div>

            <Button
              variant="primary"
              parentClasses="w-full mt-5"
              isLoading={isLoading}
              onPress={handleSubmit(onSubmit)}
              isDisabled={isDisableSubmitButton}
            >
              Activate plan
            </Button>
          </div>

          <div
            onClick={handlePayPalClick}
            className="flex cursor-pointer justify-between rounded-lg border p-6"
            role="button"
            tabIndex={0}
            aria-label="Select PayPal as payment method"
          >
            <div className="flex items-center">
              <PayPalLogoColor className="mr-2 h-8 w-[25px]" aria-label="PayPal Logo" />
              <span className="font-F37Bolton-Medium text-[16px] text-black">PayPal</span>
            </div>
            {isGeneratingPaypalToken ? (
              <LoaderComponent height={30} />
            ) : (
              <ChevronRightIcon className="size-6" />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default StripeAndPaypal;
