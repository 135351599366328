import { DateTime } from 'luxon';
import { NumericFormat } from 'react-number-format';

import { TableCell } from '@/components';
import Button from '@/components/Button/Button';

import { BillingHistoryTableRowProps } from '../../type';

const BillingHistoryTableRow = ({
  billData,
  isLoading,
  handleAction,
}: BillingHistoryTableRowProps) => {
  const payDate = DateTime.fromISO(billData?.dueDate, {
    zone: 'utc',
  }).toFormat('MMM d, y');

  return (
    <>
      <TableCell className="px-4 py-3 ">
        <span className="font-F37Bolton-Medium capitalize">{billData?.plan}</span>
      </TableCell>
      <TableCell className="px-4 py-3 text-end ">
        <span className="font-F37Bolton-Medium">{payDate}</span>
      </TableCell>
      <TableCell className="px-4 py-3 text-end">
        <NumericFormat
          value={billData?.totalAmount}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
          renderText={value => <span className="font-F37Bolton-Medium">${value}</span>}
        />
      </TableCell>
      <TableCell className="flex justify-end px-4 py-3">
        <Button
          variant="primary"
          parentClasses="text-sm bg-gray-50 hover:bg-gray-100 px-6 py-3 !text-black font-F37Bolton-Medium"
          width="w-[130px]"
          height="h-[36px]"
          isLoading={isLoading}
          onPress={handleAction}
        >
          {billData?.fsInvoiceUrl ? 'View receipt' : 'Download'}
        </Button>
      </TableCell>
    </>
  );
};

export default BillingHistoryTableRow;
